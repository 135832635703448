








import { Component, Prop, Vue } from 'vue-property-decorator'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<FilterAccordionLabel>({
  name: 'FilterAccordionLabel'
})
export class FilterAccordionLabel extends Vue {
  @Prop({ required: true, type: String })
  public label!: string

  @Prop({ required: true, type: Number })
  public count!: number
}

export default FilterAccordionLabel
